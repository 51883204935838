import "src/styles/legacy.scss"
import "src/styles/toastify.scss"
import "src/styles/index.css"
import { Auth0Provider } from "@auth0/auth0-react"
import {
  AppContextProvider,
  AuthenticationRequired,
  ErrorFallback,
  Loader,
  ModalConfirmationProvider,
  NoTrailingSlash,
  ToastsRoot,
  useImpersonificationStore,
  WithErrorBoundary
} from "@quortex/front-common"
import { captureException, init } from "@sentry/browser"
import { Outlet, ReactLocation, Router } from "@tanstack/react-location"
import { QueryClientProvider, useSuspenseQuery } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { lazy, Suspense, useEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { getEnv } from "src/helpers/env"
import { queryClient } from "src/queries/client"
import { disabledQuery } from "src/queries/specials"
import { ApiError } from "src/services/api"
import { useKeyboardDetection } from "src/stores/keyboard"
import { useRelease } from "../queries/release"
import { portalTree } from "./routes"

window.ENV = {}
window.ENV.REACT_APP_API_URL = import.meta.env.REACT_APP_API_URL

const FromBackOfficeBorder = lazy(() => import("../components/base/misc/FromBackOfficeBorder"))
const Logout = lazy(() =>
  import("@quortex/front-common").then(module => ({ default: module.Logout }))
)

// Configuring sentry's dynamic options.
const sentryDsn = import.meta.env.REACT_APP_SENTRY_DSN

if (sentryDsn) {
  init({
    beforeSend(event) {
      const exception = event.exception?.values?.[0]

      if (exception) {
        const message = exception.value || ""

        if (
          message.includes("preload") ||
          message.startsWith("Failed to fetch dynamically imported module")
        ) {
          event.fingerprint = ["network-error", "dynamic-import"]
        }
      }

      return event
    },
    dsn: sentryDsn,
    environment: getEnv(),
    release: import.meta.env.REACT_APP_SENTRY_RELEASE
  })
}

const reactLocation = new ReactLocation()

const getAuth0ClientId = (isImpersonified: boolean) => {
  const backOfficeId = import.meta.env.REACT_APP_AUTH0_CLIENT_ID_QX_BACKOFFICE
  const appId = import.meta.env.REACT_APP_AUTH0_CLIENT_ID
  return isImpersonified ? backOfficeId : appId
}

const routes = [{ element: <Logout />, path: "logout" }, portalTree]

const App = () => {
  useKeyboardDetection()
  const isImpersonified = useImpersonificationStore(state => !!state.operatorUuid)

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => <Loader error={error} isCover />}
      onError={error => {
        if (!(error instanceof ApiError)) {
          captureException(error)
        }
      }}
    >
      <Suspense fallback={<Loader isCover />}>
        <Auth0Provider
          authorizationParams={{
            audience: import.meta.env.REACT_APP_AUTH0_AUDIENCE,
            redirect_uri: window.location.origin
          }}
          cacheLocation={isImpersonified ? "memory" : "localstorage"}
          clientId={getAuth0ClientId(isImpersonified)}
          domain={import.meta.env.REACT_APP_AUTH0_DOMAIN}
          issuer={import.meta.env.REACT_APP_AUTH0_ISSUER}
          useFormData
          useRefreshTokens
          useRefreshTokensFallback
          onRedirectCallback={appState => {
            const returnTo = appState?.returnTo
            if (returnTo) reactLocation.history.push(returnTo)
          }}
        >
          <QueryClientProvider client={queryClient}>
            <CheckRelease />
            <AppContextProvider devMode={process.env.NODE_ENV === "development"}>
              <AuthenticationRequired>
                <ToastsRoot />
                <Router location={reactLocation} routes={routes}>
                  {/* <PortalRouter /> */}
                  <NoTrailingSlash />
                  <ModalConfirmationProvider>
                    <WithErrorBoundary ErrorFallback={ErrorFallback}>
                      <Outlet />
                      {isImpersonified && <FromBackOfficeBorder />}
                    </WithErrorBoundary>
                  </ModalConfirmationProvider>
                </Router>
              </AuthenticationRequired>
            </AppContextProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Auth0Provider>
      </Suspense>
    </ErrorBoundary>
  )
}

const build = {
  date: import.meta.env.BUILD_DATE as string | undefined,
  id: import.meta.env.BUILD_ID as string | undefined
}

const CheckRelease = () => {
  const releaseQuery = useRelease()
  const { data: release } = useSuspenseQuery(build.date ? releaseQuery : disabledQuery)

  useEffect(() => {
    if (release && (release.build.id !== build.id || release.build.date !== build.date)) {
      console.info("Local build not matching remote build. Reloading…", {
        local: build,
        remote: release.build
      })
      window.location.reload()
    }
  })

  return null
}

export default App
