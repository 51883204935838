import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react"
import clsx from "clsx"
import { Fragment, memo, ReactNode, MutableRefObject, Suspense, FC } from "react"
import Loader from "src/components/base/misc/Loader"

export interface Props {
  children: ReactNode
  initialFocus?: MutableRefObject<HTMLElement | null>
  modalTransitionKey?: string
  show: boolean
  onDismiss?: () => void
}

const Modal: FC<Props> = props => {
  const { children, initialFocus, modalTransitionKey, onDismiss = () => {}, show } = props

  return (
    <Transition appear as={Fragment} show={show}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[9999] overflow-y-auto"
        initialFocus={initialFocus}
        onClose={onDismiss}
      >
        <div className="flex min-h-screen flex-col items-center justify-center overflow-hidden px-4 pb-20 pt-4 text-center tablet:p-0">
          <TransitionChild
            as={Fragment}
            enter={clsx("duration-300 ease-out")}
            enterFrom={clsx("opacity-0")}
            enterTo={clsx("opacity-100")}
            leave={clsx("duration-150 ease-in")}
            leaveFrom={clsx("opacity-100")}
            leaveTo={clsx("opacity-0")}
          >
            <div className="fixed inset-0 bg-grey-900 bg-opacity-70 transition-opacity" />
          </TransitionChild>

          <Suspense
            fallback={
              <div className="relative transform pb-4 pt-8 transition-all tablet:my-8 tablet:p-6">
                <Loader isModal />
              </div>
            }
          >
            <TransitionChild
              key={modalTransitionKey}
              as={Fragment}
              enter={clsx("duration-300 ease-out")}
              enterFrom={clsx("translate-y-4 opacity-0 desktop:translate-y-0 desktop:scale-95")}
              enterTo={clsx("translate-y-0 opacity-100 desktop:scale-100")}
              leave={clsx("duration-150 ease-in")}
              leaveFrom={clsx("translate-y-0 opacity-100 desktop:scale-100")}
              leaveTo={clsx("translate-y-4 opacity-0 desktop:translate-y-0 desktop:scale-95")}
            >
              <DialogPanel className="relative transform text-left transition-all tablet:my-8">
                {children}
              </DialogPanel>
            </TransitionChild>
          </Suspense>
        </div>
      </Dialog>
    </Transition>
  )
}

/**
 * Stateless modal component.
 *
 * **Important**: This component renders its content only when it's open (`show=true`).
 * To avoid triggering API request when modals are not displayed, use a separate component for
 * modal content.
 */
export default memo(Modal)
