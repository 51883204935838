import { Panel, Title3 } from "@quortex/front-common"
import { FC, ReactNode } from "react"

interface Props {
  children: ReactNode
  title?: string
}

const StoryPanel: FC<Props> = ({ children, title }) => (
  <Panel as="section">
    {title && <Title3 className="mb-4 dark:text-grey-100">{title}</Title3>}
    {children}
  </Panel>
)

export default StoryPanel
